exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-content-security-rules-tsx": () => import("./../../../src/pages/contentSecurityRules.tsx" /* webpackChunkName: "component---src-pages-content-security-rules-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intellectual-property-rules-tsx": () => import("./../../../src/pages/intellectualPropertyRules.tsx" /* webpackChunkName: "component---src-pages-intellectual-property-rules-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-product-banned-rules-tsx": () => import("./../../../src/pages/productBannedRules.tsx" /* webpackChunkName: "component---src-pages-product-banned-rules-tsx" */),
  "component---src-pages-rulecenter-tsx": () => import("./../../../src/pages/rulecenter.tsx" /* webpackChunkName: "component---src-pages-rulecenter-tsx" */),
  "component---src-pages-service-rule-tsx": () => import("./../../../src/pages/serviceRule.tsx" /* webpackChunkName: "component---src-pages-service-rule-tsx" */)
}

